<script setup>
import { Head, usePage } from '@inertiajs/vue3';
import Alert from '@/Components/Alert.vue';
import { computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'bg-200'
    },
});

const page = usePage();

const error = computed(() => page.props.flash.error);
const success = computed(() => page.props.flash.success);
const info = computed(() => page.props.flash.info);

const typeClass = computed(() => {
    switch (props.type) {
        case 'primary':
            return 'bg-primary text-primary-content';
        default:
            return 'bg-base-200';
    }
});

</script>

<template>
    <Head :title="$t('auth.default_title')" />

    <div v-if="error" class="toast toast-bottom toast-end z-50">
        <Alert type="error" :dismissable="true" :message="error"></Alert>
    </div>

    <div v-if="success" class="toast toast-bottom toast-end z-50">
        <Alert type="success" :dismissable="true" :message="success"></Alert>
    </div>

    <div v-if="info" class="toast toast-bottom toast-end z-50">
        <Alert :dismissable="true" :message="info"></Alert>
    </div>

    <div class="px-4 -mb-4 min-h-screen w-full relative overflow-x-hidden" :class="[ typeClass ]">
        <slot></slot>
    </div>

</template>
