<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    message: {
        type: String,
        required: false
    },
    type: {
        type: String,
        default: 'info'
    },
    dismissable: {
        type: Boolean,
        default: false,
    }
});

const visible = ref(true);

const typeClass = computed(() => {
    switch (props.type) {
        case 'success':
            return 'alert-success border-success-content';
        case 'warning':
            return 'alert-warning border-warning-content';
        case 'error':
            return 'alert-error border-error-content';
        default:
            return 'alert-info border-info-content';
    }
});

const typeIconClass = computed(() => {
    switch (props.type) {
        case 'success':
            return 'far fa-check';
        case 'warning':
            return 'far fa-exclamation-triangle';
        case 'error':
            return 'far fa-exclamation-triangle';
        default:
            return 'far fa-lightbulb-on';
    }
});

const dismiss = () => {
    if(props.dismissable) {
        visible.value = false;
    }
};
</script>

<template>
    <div v-if="visible" role="alert" class="alert flex flex-row gap-3 rounded-lg border" :class="[typeClass, props.dismissable ? 'cursor-pointer' : '']" @click="dismiss">
        <div>
            <i :class="typeIconClass"></i>
        </div>
        <div class="text-pretty text-sm text-left">
            <template v-if="$slots.message">
                <slot name="message"></slot>
            </template>
            <template v-else>
                {{ props.message }}
            </template>
        </div>
    </div>
</template>
